<!-- 职业教育--- -->
<template>
  <div style="height: 100%">
    <el-card>
      <div class="top">
        筛选学科：
        <el-select
          v-model="value"
          placeholder="请选择学科"
          size="mini"
          filterable
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
        <el-button
          type="primary"
          size="mini"
          style="margin-left: 30px"
          @click="OnReset(1)"
          >搜索</el-button
        >
        <el-button size="mini" style="margin-left: 30px" @click="OnReset"
          >重置</el-button
        >
        <el-button type="primary" class="set" size="mini" @click="OnSet(1)"
          >创建学科</el-button
        >
      </div>
      <el-scrollbar>
        <el-card class="box-card">
          <!-- 表格 -->
          <el-table
            :data="tableData"
            style="width: 100%"
            :header-cell-style="{
              background: '#F3F7FF',
              color: '#0B1B31',
              fontSize: '14px',
              fontWeight: '500',
            }"
          >
            <el-table-column label="学科id" align="center" width="180">
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{ scope.row.id }}</span>
              </template>
            </el-table-column>
            <el-table-column label="学科名字" align="center">
              <template slot-scope="scope">
                <p>{{ scope.row.name }}</p>
              </template>
            </el-table-column>
            <el-table-column label="创建人" align="center">
              <template slot-scope="scope">{{ scope.row.createName }}</template>
            </el-table-column>
            <el-table-column label="创建时间" align="center">
              <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column label="修改人" align="center">
              <template slot-scope="scope">{{ scope.row.updateName }}</template>
            </el-table-column>
            <el-table-column label="修改时间" align="center">
              <template slot-scope="scope">{{ scope.row.updateTime }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="150">
              <template slot-scope="scope">
                <i
                  class="el-icon-edit"
                  @click="OnSet(2, scope.$index, scope.row)"
                ></i>
                <i
                  style="margin-left: 40px"
                  class="el-icon-delete"
                  @click="handleDelete(scope.$index, scope.row)"
                ></i>
              </template>
            </el-table-column>
          </el-table>
          <!-- //分页 -->
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="page.pageSize"
            :current-page.sync="page.pageNum"
            layout="total, prev, pager, next"
            :total="page.total"
            background
            style="margin-top: 20px; text-align: center"
          ></el-pagination>
        </el-card>
      </el-scrollbar>
    </el-card>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialog.title"
      :visible.sync="dialog.dialogVisible"
      width="30%"
    >
      <div class="dialog">
        <el-form
          ref="formLabelAlign"
          :rules="rules"
          label-position="right"
          label-width="100px"
          :model="formLabelAlign"
        >
          <el-form-item label="学科名称：" prop="name">
            <el-input
              v-model="formLabelAlign.name"
              style="width: 300px"
              size="mini"
            ></el-input>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              v-model="formLabelAlign.remark"
              style="width: 300px"
              size="mini"
            ></el-input>
          </el-form-item>
          <div class="bottom">
            <el-button type="primary" @click="OnDialogSet">确认</el-button>
            <el-button @click="OnDialogSet(1)">取消</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  subjectAdd,
  subjectUpdate,
  subjectList,
  subjectDetail,
  subjectDelete,
} from "../../../api/api";
export default {
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "学科名称不可为空", trigger: "blur" },
        ],
      },
      tableData: [],
      options: [{ value: 0, label: "全部" }],
      formLabelAlign: {
        name: "",
        remark: "",
        id: null,
      },
      value: "全部",
      tableDatalist: 0,
      page: {
        pageSize: 10,
        total: 100,
        pageNum: 1,
      },
      dialog: {
        dialogVisible: false,
        title: "新建标题",
      },
    };
  },

  components: {},
  mounted() {
    this.init();
  },
  watch: {},
  methods: {
    search() {
      this.options = [
        {
          value: 0,
          label: "全部",
        },
      ];
      subjectList({}).then((res) => {
        res.data.list.map((item, index) => {
          var obj = {};
          obj.value = item.id;
          obj.label = item.name;

          this.options.push(obj);
        });
      });
    },
    //初始化
    init(name = "") {
      this.search();
      subjectList({
        pageNum: this.page.pageNum,
        pageSize: this.page.pageSize,
        name: name,
      }).then((res) => {
        this.page.total = res.data.total;
        this.tableDatalist = res.data.list.length;
        res.data.list.map((item, index) => {
          item.createTime = item.createTime
            ? this.duration.getDateStr(item.createTime)
            : "--";
          item.updateTime = item.updateTime
            ? this.duration.getDateStr(item.updateTime)
            : "--";
          item.updateName = item.updateName ? item.updateName : "暂无修改";
        });
        this.tableData = res.data.list;
      });
    },
    //重置与搜索1表示搜索
    OnReset(type) {
      this.page.pageNum = 1;
      //   this.tableDatalist
      if (type == 1) {
        var name = this.value;
        if (this.value == "全部") {
          name = "";
        }
        this.init(name);
        return;
      }
      this.value = "全部";
      this.init();
    },
    //新建按钮--
    OnSet(num, index, row) {
      if (num == 1) {
        this.dialog.title = "新建学科";
        this.formLabelAlign.name = "";
        this.formLabelAlign.remark = "";
        this.formLabelAlign.id = "";
      } else {
        this.dialog.title = "编辑学科";
        this.formLabelAlign.name = row.name;
        this.formLabelAlign.remark = row.remark;
        this.formLabelAlign.id = row.id;
      }
      this.dialog.dialogVisible = true;
    },
    //创建活动
    OnDialogSet(type) {
      if (type == 1) {
        this.$refs["formLabelAlign"].resetFields();
        this.dialog.dialogVisible = false;
        return;
      }
      this.$refs.formLabelAlign.validate((valid) => {
        if (valid) {
          if (this.dialog.title == "新建学科") {
            subjectAdd({
              name: this.formLabelAlign.name,
              remark: this.formLabelAlign.remark,
            }).then((res) => {
              if (res.message == "请求成功") {
                this.$message({
                  message: "新建成功",
                  type: "success",
                });
                this.$refs["formLabelAlign"].resetFields();
                this.dialog.dialogVisible = false;
                //刷新
                this.value = "全部";
                this.init();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            subjectUpdate({
              name: this.formLabelAlign.name,
              remark: this.formLabelAlign.remark,
              id: this.formLabelAlign.id,
            }).then((res) => {
              if (res.message == "请求成功") {
                this.$message({
                  message: "编辑成功",
                  type: "success",
                });
                this.dialog.dialogVisible = false;
                //刷新
                this.value = "全部";
                this.init();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //删除
    handleDelete(index, row) {
      this.$confirm("此操作将永久删除该学科, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          subjectDelete({ id: row.id }).then((res) => {
            if (res.message == "请求成功") {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              if (this.tableDatalist == 1) {
                this.page.pageNum = this.page.pageNum - 1;
              }
              this.value = "全部";
              this.init();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //分页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.init();
    },
  },
};
</script>
<style lang='less' scoped>
@import "./index.less";
/deep/.el-scrollbar {
  height: 100%;
}
</style>
